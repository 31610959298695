import React, { useEffect } from 'react'
import { useState } from 'react'
import { Progress, Checkbox } from 'antd';
import { ReactComponent as ConnectionLink } from "../../assets/images/connection-link.svg"
import { useForm } from 'react-hook-form'
import { createApiClient } from '@oneblinktech-org/helios-base';
import { notification } from 'antd';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put, remove } = createApiClient({ apiUrl });

function ConnectionSteps({ connection, closeModal }) {
    const [step, setStep] = useState(1)
    const { register, formState: { errors }, handleSubmit, getValues } = useForm({})
    const [connectionTypes, setConnectionTypes] =  useState('')

    useEffect(() => {
        setStep(1)
    }, [connection])

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('h-access_token');

            try {
                const result = await get('/connectionTypes', {
                    "Authorization": `Bearer ` + token
                })

                setConnectionTypes(result);
            } catch (e) {
                console.error('Error fetching connection types:', e);
            }
        };

        fetchData();
    }, []);

    const onNotification = (description) => {
        notification.open({
            message: 'Alert!',
            description: description,
            onClick: () => {
            },
        });
    }

    async function onSaveChanges(data) {
        if (! connectionTypes.CCCSECURESHARE) {
            onNotification('Connection type API is wrong!')
            return;
        }

        if (data) {
            const token = localStorage.getItem('h-access_token')

            let createData = {
                'key': data.access_token,
                'type': 'CCCSECURESHARE',
            }
            
            post('/connections/connect', createData, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                setStep(3)
            }).catch(e => {
                errors.access_token = 'Something went wrong! Try again.'
            })
        }
    }

    return (
        <div>
            <div className='step-modal' key={step} >
                <div className='step-content'>
                    <div className='step-header'>
                        <b className='label' >{`Step ${step}`}</b>
                        <Progress percent={`${((step / 3) * 100)}`} showInfo={false} />
                    </div>
                </div>
                {/* step 1 */}
                {step === 1 &&
                    <div>
                        <span className='step-info' >At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod.</span>

                        <div className='action'>
                            <Checkbox className='terms' >I agree to the CCCSecure Share Terms & Conditions and Privacy Policy.</Checkbox>
                            <div className='footer-btn'>
                                <button className='connect' onClick={() => setStep(2)}>Continue</button>
                                <button className='cancel' onClick={() => closeModal()} >Cancel</button>

                            </div>
                        </div>
                    </div>
                }

                {/* step 2 */}
                {step === 2 &&
                    <div>
                        <span className='step-info'>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti.</span>
                        <form onSubmit={handleSubmit(onSaveChanges)}>
                            <div className='step2-action'>
                                <div className='input-container'>
                                    <div className='input-box'>
                                        <input
                                            className=''
                                            placeholder="Enter your CCC Access Token*"
                                            type="text"
                                            name='access_token'
                                            {...register("access_token", {
                                                required: { value: true, message: 'Enter a valid key' },
                                                minLength: { value: 10, message: 'Enter a valid key' },
                                            })}
                                        />
                                    </div>
                                    <div className='input-footer'>
                                        <p className='inp-error'>{errors?.access_token?.message??''}</p>
                                        <div className='counter'>{`${getValues('access_token')?.length??0}/50`}</div>
                                    </div>
                                </div>
                                <div className='footer-btn'>
                                    <button className='connect' type='submit'>
                                        <ConnectionLink fill={'#ffffff'} />
                                        Connect
                                    </button>
                                    <button className='cancel' onClick={() => setStep(1)} >Back</button>

                                </div>
                            </div>
                        </form>
                    </div>
                }

                {/* step 3 */}
                {step === 3 &&
                    <div className='d-flex flex-column' >
                        <span className='step-info'>Your have been connected to the CCCSecure Share successfully.</span>
                        <span className='step-info'>Thank you!</span>
                        <div className='action'>
                            <div className='footer-btn'>
                                <button className='connect' onClick={() => closeModal()}>
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default ConnectionSteps