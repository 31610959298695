import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import ExpandedCard from './modals/ExpandedCard';
import { debouncedGetChooseJob, resetOpenJob, setIsNewJob } from '../store/job/actions';
import Pusher from 'pusher-js';

export const JobModal = (props) => {
    const modalRef = React.useRef(null);
    const [selectedJob, setSelectedJob] = useState(props.newJob.data);
    const [archivedJob, setArchivedJob] = useState(false);
    const [pulseAnimation, setPulseAnimation] = useState(false)

    const history = useHistory();
    const { isNewJob, setIsNewJob } = props

    useEffect(() => {
        setSelectedJob(props.newJob.data)
    }, [props.newJob.data])

    useEffect(() => {
        var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        });

        var channel = pusher.subscribe("product-board");
        channel.bind("job-event", (data) => {
            if (data.event === 'card changed') {
                if (data?.value) {
                    props.debouncedGetChooseJob(data.value, false)
                }
            }
        });

        return (() => {
            pusher.unsubscribe('product-board')
        })
    }, []);

    const changeBackground = (color) => {
        setSelectedJob({ ...selectedJob, color_id: color?.id, job_color: color })
    }

    const onArchiveJob = (status) => {
        // status - 0: archive, 1: unarchive
        if (status === 0) {
            setArchivedJob(true)
        } else if (status === 1) {
            setPulseAnimation(false)
            setArchivedJob(false)
            setTimeout(() => {
                setPulseAnimation(false)
            }, 5000)
        }
    }

    return (
        <>
            <div className="expandedcard_section">
                <div
                    id="expandCard"
                    className={`${pulseAnimation ? `pulseAni` : ''}`}
                >
                    {!props.newJob.loading &&
                        <div className='card-color-line' style={{ backgroundColor: (props?.colorList?.data?.length > 0 && props.newJob.data?.color_id) ? `${props?.colorList?.data.find(data => { return data?.id == selectedJob?.color_id })?.['color']}` : '' }} />
                    }
                    <div
                        className="expandedcard_modal"
                        style={{ backgroundColor: 'white' }}
                    >
                        <ExpandedCard
                            isCardChanged={false}
                            item={props.newJob.data}
                            changeBackground={(color) => changeBackground(color)}
                            currentUser={props.user}
                            loading={props.newJob.loading}
                            onArchiveJob={() => onArchiveJob()}
                            isNewJob={isNewJob && Object.keys(props.newJob.data)?.length === 0} />
                    </div>
                    <img ref={modalRef}
                        className="extend-modal-close modal-close"
                        alt=""
                        src={require(
                            '../assets/images/production_board/modal-close.svg').default}
                        onClick={(e) => {
                            e.preventDefault()
                            window.history.pushState({}, '', `#${history.location.pathname}`)
                            setSelectedJob({})
                            setIsNewJob(false)
                            if (props.newJob?.isFirstJob)
                                history.push("/planning")
                        }}
                    />
                </div>
            </div>
            <div className="expandCardBack"
                onClick={(e) => {
                    e.preventDefault()
                    window.history.pushState({}, '', `#${history.location.pathname}`)
                    setSelectedJob({})
                    props.resetOpenJob()
                    setIsNewJob(false)
                    if (props.newJob?.isFirstJob)
                        history.push("/planning")
                }}
            ></div>
        </>
    )
}

const mapStateToProps = (state) => ({
    jobStatuses: state.JobReducer.jobStatuses,
    colorList: state.JobReducer.colorList,
    assignedUsers: state.JobReducer.assignedUsers,
    filterOptions: state.JobReducer.filterOptions,
    user: state.UserReducer.user,
    newJob: state.JobReducer.newJob,
    isNewJob: state.JobReducer.isNewJob
})

const mapDispatchToProps = (dispatch) => {
    return {
        debouncedGetChooseJob: (id, loading) => debouncedGetChooseJob(dispatch, id, loading),
        resetOpenJob: () => dispatch(resetOpenJob()),
        setIsNewJob: (isNew) => dispatch(setIsNewJob(isNew))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobModal)