import {
    GET_USER_TYPE,
    GET_PROFILE,
    UPDATE_PROFILE,
    INITIAL_STATES,
    UPDATE_USER_IN_ORG,
    ADD_USER_IN_ORG
} from "./actionTypes";
import debounce from 'lodash.debounce';

export const getProfile = () => {
    return {
      type: GET_PROFILE
    }
}

export const debouncedGetProfile = debounce((dispatch) => {
  dispatch(getProfile());
}, 300);

export const getProfileSuccess = (payload) => {
    return {
      type: GET_PROFILE + `_SUCCESS`,
      payload: payload
    }
}

export const getProfileFailed = (error) => {
    return {
        type: GET_PROFILE + `_FAILED`,
        error: error
    }
}

export const updateProfile = (data) => {
    return {
      type: UPDATE_PROFILE,
      payload: data
    }
}

export const debouncedUpdateProfile = debounce((dispatch, data) => {
  dispatch(updateProfile(data));
}, 300);

export const updateProfileSuccess = (data) => {
    return {
      type: UPDATE_PROFILE + `_SUCCESS`,
      payload: data
    }
}

export const updateProfileFailed = (data) => {
    return {
      type: UPDATE_PROFILE + `_FAILED`,
      payload: data
    }
}

export const getUserTypes = () => {
  return {
    type: GET_USER_TYPE
  }
}

export const getUserTypesSuccess = (payload) => {
  return {
    type: GET_USER_TYPE + `_SUCCESS`,
    payload: payload
  }
}

export const getUserTypesFailed = (payload) => {
  return {
    type: GET_USER_TYPE + `_FAILED`,
    payload: payload
  }
}

export const updateUserInOrg = (payload) => {
  return {
    type: UPDATE_USER_IN_ORG,
    payload: payload
  }
}

export const updateUserInOrgSuccess = (payload) => {
  return {
    type: UPDATE_USER_IN_ORG + `_SUCCESS`,
    payload: payload
  }
}

export const updateUserInOrgFailed = (payload) => {
  return {
    type: UPDATE_USER_IN_ORG + `_FAILED`,
    payload: payload
  }
}

export const addUserInOrg = (payload) => {
  return {
    type: ADD_USER_IN_ORG,
    payload: payload
  }
}

export const addUserInOrgSuccess = (payload) => {
  return {
    type: ADD_USER_IN_ORG + `_SUCCESS`,
    payload: payload
  }
}

export const addUserInOrgFailed = (payload) => {
  return {
    type: ADD_USER_IN_ORG + `_FAILED`,
    payload: payload
  }
}

export const initial = () => {
    return {
        type: INITIAL_STATES,
    }
}