import React, { useState, useEffect } from 'react'
import {useHistory, Link, useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import { Switch, Space, Pagination, Tooltip, Spin } from 'antd'
import ReactTable from "react-table";  
import "react-table/react-table.css";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Input } from 'reactstrap'
import { OutlinedButton, FilledBrightButton } from '@oneblinktech-org/helios-base'
import {
    getUserTypes,
    updateUserInOrg
} from '../../store/user/actions'
import $ from 'jquery';
import { displayShortName } from '../../helpers/globals';
import { plus } from '../../fakeData/svgFiles';

const DEFAULT_PAGE_SIZE = 20

const customStyles = {
    headCells: {
        style: {
            paddingLeft: '2px',
            paddingRight: '2px',
        },
    },
    cells: {
        style: {
            paddingLeft: '2px',
            paddingRight: '2px',
        },
    },
}

const columns = [
    {
        Header: "Name",
        id: "lastName",
        accessor: row => 
            <div className="d-flex flex-row align-items-center">
                <div className='text-uppercase user_avatar'>
                    {displayShortName(row)}
                </div>
                <span className={(!row.is_active ? 'inactive' : '') + ' name'}>{row.firstname + ' ' + row.lastname}</span>
            </div>
    },
    {
        Header: "Email",
        id: "email",
        accessor: row => <span className={!row.is_active ? 'inactive' : '' }>{row.email}</span>
    },
    {
        Header: "Role",
        id: "userType",
        accessor: row => <span className={!row.is_active ? 'inactive' : ''}>{row.role ? row?.role === 'Subordinate' ? 'Regular User' : row.role : ''}</span>
    },
    {
        Header: "Status",
        id: "is_active",
        accessor: row =>
        <div className="d-flex flex-row align-items-center">
            <div className={(row.is_active ? 'user_active' : 'user_inactive') + " job_status"} ></div>
            <span className={(!row.is_active ? 'inactive' : '') + ' ml-2' }>{row.is_active ? 'active' : 'inactive'}</span>
        </div>
    },
    {
        Header: "Action",
        id: 'action',
        accessor: d => <Tooltip placement="bottomLeft" title="Edit"><img className="pointer" name="edit-action" alt="Edit" src={require("../../assets/images/pencil.svg").default} /></Tooltip>
    },
]

const ExpandableTable = (props) => {
    const history = useHistory()

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [aUserExpanded, setAUserExpanded] = useState({})
    const [inactiveUser, setInactiveUser] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        props.getUserTypes()
    }, [])

    useEffect(() => {
        if (props.data.length > 0) {
            const data = props.data.filter(i => i.role !== 'Customer')
            setData(data.filter(item => item.is_active))
        }
    }, [props.data])

    const onTableChange = (pagination, filters, sorter, extra) => {

    }

    const onPagiChange = (pageNum) => {
        setCurrentPage(pageNum)
        props.getData(pageNum - 1)
    }

    const onSwitch = (checked) => {
        setInactiveUser(checked)

        if (checked) {
            setData(props.data)
        } else {
            let copyData = data.filter(item => item.is_active == 1)
            setData(copyData)
        }
    }

    const onUserActive = (checked, row) => {
        let tempD = []
        if (!checked && window.confirm("Are you sure?")) {
            tempD = props.data.map(item => {
                if (item.id == row.id) {
                    item.is_active = 0
                }
                return item
            })

        } else {
            tempD = props.data.map(item => {
                if (item.id == row.id) {
                    item.is_active = 1
                }
                return item
            })
        }

        Object.keys(aUserExpanded).map(expand => { return aUserExpanded[expand] = false })
        if (inactiveUser) {
            setData(tempD)
        } else {
            $(`#rowId_${row.id}`).fadeOut("slow", async function () {
                const data = await tempD.filter(i => i.role !== 'Customer')
                setData(data.filter(item => item.is_active == 1))
            });
        }

        props.updateUserInOrg(row)
    }

    const handleNewUser = (e) => {
        props.setNewUser({
            ...props.newUser,
            [e.target.name]: e.target.value
        })
    }


    return (
        <div>
            <div className="d-flex flex-column flex-md-row justify-content-end align-items-center mb-4">
                {/*<div className="input-group search col-12 col-xl-7">*/}
                {/*    <div className="input-group-prepend">*/}
                {/*        <span className="input-group-text p-0" id="inputGroupPrepend2">*/}
                {/*            <img src={require('../../assets/images/header/search.svg').default} alt="" />*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <Input placeholder={props.searchPlaceholder} type="text" className="form-control" id="header-search" aria-describedby="inputGroupPrepend2" />*/}
                {/*</div>*/}
                <div className="d-flex flex-row align-items-center col-12 col-xl-5">
                    <label className="table-toggle-text my-0 mr-2 ml-auto">Show {props.toggleTxt}</label>
                    <Switch onChange={onSwitch} />
                    <div className="pointer reload-btn" onClick={() => props.refreshTable()}>
                        <img className="ml-3 mr-2" src={require('../../assets/images/reload.svg').default} alt="" />
                        <label className="uppercase m-0">Reload Table</label>
                    </div>
                    {
                        props.isExpert &&
                        <div className="pointer reload-btn" onClick={() => props.export()}>
                            <img className="ml-3 mr-2" src={require('../../assets/images/export.svg').default} alt="" />
                            <label className="uppercase m-0">Export</label>
                        </div>
                    }
                </div>
            </div>
            <div className='users-table'>
            {
                data.length > 0 &&
                <ReactTable
                    data={data}
                    columns={columns}
                    expanded={aUserExpanded}
                    pageSize={data.length}
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: e => {
                                if (e.target.name == "edit-action") {
                                    const expandedT = { ...aUserExpanded };
                                    expandedT[rowInfo.viewIndex] = aUserExpanded[rowInfo.viewIndex] ? false : true;
                                    setAUserExpanded({ [rowInfo.viewIndex]: true });
                                }
                            },
                            style: {
                                animation: rowInfo?.viewIndex?.toString() === Object.keys(aUserExpanded)[0] ? 'fadeOut 0.3s' : 'fadeIn 0.3s ease-in-out',
                                opacity: rowInfo?.viewIndex?.toString() === Object.keys(aUserExpanded)[0] ? 0 : 1,
                                height: rowInfo?.viewIndex?.toString() === Object.keys(aUserExpanded)[0] ? 0 : 'auto',
                            }
                        };
                    }}
                    SubComponent={row => {
                        return (
                            <div
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5 }}
                                className="content row subcomponent" id={`rowId_${row.original.id}`}>
                                <div className='col-12 col-md-2 edit-wrap item d-flex flex-row align-items-center'>
                                    <div className='text-uppercase user_avatar'>
                                        {displayShortName(row.original)}
                                    </div>
                                    <div>
                                        <div>
                                            <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                autoComplete="username"
                                                value={row.original.firstname + ' ' + row.original.lastname}
                                                onChange={(e) => {
                                                    let tempUser = data.map(item => {
                                                        if (item.id == row.original.id) {
                                                            item.firstname = (e.target.value).split(' ')[0]
                                                            item.lastname = (e.target.value).split(' ')[1]
                                                        }

                                                        return item
                                                    })

                                                    setData(tempUser)
                                                    props.updateUserInOrg(row.original)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-2 edit-wrap item'>
                                    <div>
                                        <input
                                            type="text"
                                            name="useremail"
                                            id="useremail"
                                            autoComplete="useremail"
                                            value={row.original.email}
                                            onChange={(e) => {
                                                let tempUser = data.map(item => {
                                                    if (item.id == row.original.id) {
                                                        item.email = e.target.value
                                                    }

                                                    return item
                                                })

                                                setData(tempUser)
                                                props.updateUserInOrg(row.original)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 col-md-2 edit-wrap item'>
                                    <div className='disable-input'>
                                        <input
                                            type="text"
                                            name="useremail"
                                            id="useremail"
                                            autoComplete="useremail"
                                            value={row.original.role === 'Subordinate' ? 'Regular User' : row.original.row}
                                            onChange={(e) => {
                                                let tempUser = data.map(item => {
                                                    if (item.id == row.original.id) {
                                                        item.role = e.target.value
                                                    }
                                                    return item
                                                })

                                                setData(tempUser)
                                                props.updateUserInOrg(row.original)
                                            }}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-2 item">
                                    <div>
                                        <Switch onChange={(checked) => onUserActive(checked, row.original)} checked={row.original.is_active ? true : false} />
                                        <span className="table-toggle-text my-0 ml-2">Active</span>
                                    </div>
                                </div>
                                <div className="col-2 item d-flex flex-row gap-3 ">
                                    <OutlinedButton title='Send Password Reset' className='resetpass' />
                                    <img alt="" className='pointer' src={require('../../assets/images/production_board/modal-close.svg').default} onClick={() => setAUserExpanded({})} />
                                </div>
                            </div>
                        );
                    }}
                />
            }
            {
                data.length > 0 && Math.ceil(data.total / DEFAULT_PAGE_SIZE) > 1 &&
                <Space className="custom-table-pos d-flex flex-row justify-content-around py-2">
                    <OutlinedButton
                        title="First"
                        className="uppercase"
                        disabled={currentPage == 1 ? true : false}
                        clickEvent={(e) => onPagiChange(1)}
                    />
                    <Pagination current={currentPage} defaultPageSize={DEFAULT_PAGE_SIZE} total={data.total} onChange={onPagiChange} showSizeChanger={false} />
                    <OutlinedButton
                        title="Last"
                        className="uppercase"
                        disabled={currentPage == Math.ceil(data.total / DEFAULT_PAGE_SIZE) ? true : false}
                        clickEvent={(e) => onPagiChange( Math.ceil(data.total / DEFAULT_PAGE_SIZE) )}
                    />
                </Space>
            }
                {
                    props.userFToogle &&
                    <>
                        {
                            data.length == 0 &&
                            <div className="new-user_row d-flex flex-row content profile_temp-title">
                                <div className='edit-wrap title my-2'>
                                    Name
                                </div>
                                <div className='edit-wrap title my-2'>
                                    Email
                                </div>
                                <div className='edit-wrap m-auto title my-2'>
                                    Role
                                </div>
                                <div className='edit-wrap m-auto title my-2'>
                                    Status
                                </div>
                                <div className='edit-wrap title my-2'>
                                    Action
                                </div>
                            </div>
                        }
                        <div className="new-user_row d-flex flex-row content">
                            <div className='edit-wrap'>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="name"
                                    vaule={props.newUser.name}
                                    onChange={handleNewUser}
                                />
                            </div>
                            <div className='edit-wrap'>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    vaule={props.newUser.email}
                                    onChange={handleNewUser}
                                />
                            </div>
                            <div className='edit-wrap m-auto'>
                            </div>
                            <div className='edit-wrap m-auto mobile-status'>
                                <Switch
                                    name="is_active"
                                    id="is_active"
                                    onChange={(checked) => {
                                        props.setNewUser({
                                            ...props.newUser,
                                            ['is_active']: checked
                                        })
                                    }}
                                    defaultChecked={true}
                                    value={props.newUser.is_active}
                                />
                                <span className="table-toggle-text my-0 ml-2">{props.newUser.is_active ? 'Active' : 'Inactive'}</span>
                            </div>
                            <div className='edit-wrap'>
                                {props.saveLoading ?
                                    <Spin className='mt-2' />
                                    :
                                    <FilledBrightButton
                                        type="button"
                                        title="Save"
                                        clickEvent={() => props.addNewUserInOrg()}
                                        className="mt-2"
                                    />
                                }
                            </div>
                        </div>
                    </>
                }
                <div className="px-3">
                    <OutlinedButton
                        svgFile={plus}
                        title="Add New User"
                        clickEvent={(e) => {
                            props.setUserFToogle(true)
                        }}
                        className="mt-2"
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        types: state.UserReducer.user_types.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserTypes: () => dispatch(getUserTypes()),
        updateUserInOrg: (data) => dispatch(updateUserInOrg(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableTable)