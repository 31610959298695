import React from 'react'
import { connect } from 'react-redux'
import { setIsNewJob } from '../store/job/actions'

export const NewJobMenuLink = (props) => {
    return (
        <div className="job-item" onClick={(e) => props.setIsNewJob(true)}>
            {props.children}
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return {
        setIsNewJob: (isNew) => dispatch(setIsNewJob(isNew))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewJobMenuLink)