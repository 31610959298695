import React, { useState, useEffect, useRef, useCallback } from 'react'
import {useHistory, Link, useParams} from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Button, FormGroup, Input, Alert } from 'reactstrap'
import Select, { components } from 'react-select'
import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format'
import Slider from 'react-rangeslider'
import state from '../../fakeData/state'
import NewCustomer from '../../components/modals/NewCustomer'
import {useForm, Controller} from 'react-hook-form'
import { Modal, notification, Spin, Select as NSelect } from 'antd'
import { OutlinedButton } from '@oneblinktech-org/helios-base'
import { plus } from '../../fakeData/svgFiles.js'
import { useDocumentTitle } from '../../components/useDocumentTitle'
import {
    debouncedGetVehicleMakes,
    debouncedCreateNewJob,
    initial,
    debouncedGetChooseJob,
    debouncedGetCustomers,
    debouncedGetAllAssignableUsers,
    setModal
} from '../../store/job/actions'

const NewJob = (props) => {

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false)
    const [warning, setWarning] = useState(false)
    const [customerFocus, setCustomerFocus] = useState(false)
    const [assignableUserFocus, setassignableUserFocus] = useState(false)
    const [makeFocus, setMakeFocus] = useState(false)

    const { id } = useParams()
    const history = useHistory()

    const { Option } = NSelect;

    useEffect(() => {
        props.debouncedGetCustomers()
        props.initial()
        props.debouncedGetAllAssignableUsers()
        props.debouncedGetVehicleMakes()
        if(id) {
            props.debouncedGetChooseJob(id, true)
        }
    }, [id])

    useEffect(() => {
        if (props.newJob.message != "") {
            if (id) {
                history.push("/production?jobId=" +id)
            } else {
                history.push("/planning")
                props.initial()
            }
        }
    }, [props.newJob.message])

    useEffect(() => {
        let isMounted = true
        if(isMounted) {

            setValue('vin', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.vin : '')
            setValue('license_plate_number', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.license_plate_number : '')
            setValue('model', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.model : '')
            setValue('exterior_color', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.exterior_color : '')
            setValue('paint_code', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.paint_code : '')
            setValue('trim_code', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.trim_code : '')
            setValue('ro_number', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.ro_number : '')
            setValue('insurance_company', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.insurance_company : '')
            setValue('year', Object.keys(props.newJob.data).length > 0 ? new Date(props.newJob.data.vehicle.year, '01', '01') : new Date())
            setValue('make_id', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.vehicle.make : '')
            setValue('customer', props.customer)
            setValue('assignableUser', Object.keys(props.newJob.data).length > 0 ? props.newJob.data.assigned_to_user : null)
        }

        return () => {
            isMounted = false
        }
    }, [props.newJob.data, props.customer])

    const horizontalLabels = {
        0: 'E',
        25: '1/4',
        50: '1/2',
        75: '3/4',
        100: 'F'
    }

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: '#777D74',
          backgroundColor: '$blue-focus-bg'
        }),
        control: (provided) => ({
          ...provided,
          marginTop: "5%",
          backgroundColor: '$blue-focus-bg',
          color: '#777D74'
        }),
        singleValue: (provided) => ({
            ...provided,
            backgroundColor: '$blue-focus-bg',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: state.hasValue ? 'none' : ''
        })
    }

    const onRadioBtnClick = (rSelected) => {
        this.setState({ rSelected });
    }

    const { register, handleSubmit, setValue, getValues, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            vehicle_ownership_status: true,
            vehicle_drivable_status: true,
            customer: {},
            assignableUser: {},
            vehicle_fuel: 0,
            year: new Date(),
            expiry: new Date(),
            vehicle_mileage_in: 0,
            vehicle_mileage_out: 0
        }
    })

    const watchAllFields = watch()

    const submitJob = (data) => {

        let createData = {
            customer_id: data.customer.id,
            vehicle_vin: data.vin,
            vehicle_license_plate_number: data.license_plate_number,
            vehicle_year: data.year.getFullYear(),
            vehicle_make_id: data.make_id.id,
            vehicle_model: data.model,
            vehicle_exterior_color: data.exterior_color,
            vehicle_paint_code: data.paint_code,
            vehicle_trim_code: data.trim_code,
            ro_number: data.ro_number,
            insurance_company: data.insurance_company,
        };

        if (data.assignableUser) {
            createData = {
                ...createData,
                assigned_to_user_id: data.assignableUser.id
            }
        }

        if (id) {
            createData = {
                id: id,
                color_id: props.newJob.data.color_id,
                ...createData
            }

            // props.createNewJob(createData, 1)
            props.debouncedCreateNewJob( createData, 1)
        } else {
            createData = {
                type_id: 1, //no input for this as well - defaulting for now until job types are defined
                color_id: 1, //no input for this - defaulting for now until job statuses are defined
                ...createData
            }

            // props.createNewJob(createData, 0)
            props.debouncedCreateNewJob(createData, 0)
            dispatch({type: "INCREASE_COUNTER_DATA"})
        }
        setWarning(false)
    }

    const {ref: vinRef, ...vinRest} = register("vin", {required: true})
    const {ref: licensePlateRef, ...licensePlateRest} = register("license_plate_number", {required: true})
    const {ref: modelRef, ...modelRest} = register("model", {required: true})
    const {ref: ro_numberRef, ...ro_numberRest} = register("ro_number", {required: true})
    const {ref: exteriorColorRef, ...exteriorColorRest} = register("exterior_color", {required: true})
    const {ref: paintCodeRef, ...paintCodeRest} = register("paint_code", {required: true})
    const {ref: trimCodeRef, ...trimCodeRest} = register("trim_code", {required: true})
    const {ref: insuranceCompanyRef, ...insuranceCompanyRest} = register("insurance_company", {required: true})

    const make = watch("make_id", null)
    const customer = watch("customer", null)
    const assignableUser = watch("assignableUser", null)

    useDocumentTitle("Helios" + (id ? " - Edit Job" : " - New Job") )

    const onNewCustomer = async () => {
        await props.setOpenModal(true)
        await setModal(!modal)
    }
    
    return (
        <Spin spinning={props.newJob.loading}>
            <form onSubmit={handleSubmit(submitJob)} autoComplete="off">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                        <h6 className="tool-title">Job {(id && getValues('ro_number')) ? `#${getValues('ro_number')}` : 'creation'}</h6>
                        <div className="d-flex flex-row align-items-center">
                            <h6 className="tool-title">
                                <Link to="/jobs">{id ? 'Cancel' : 'Cancel Job Creation'}</Link>
                            </h6>
                            <OutlinedButton
                                disabled={props.newJob.loading}
                                type="submit"
                                title= {id ? 'Update' : 'Save'}
                                clickEvent={(e) => {}}
                                className="ml-3"
                            />
                        </div>
                    </div>
                    <div className="job-content">
                        {
                            (Object.keys(props.newJob.errors).length > 0 || Object.keys(props.newJob.errors).length > 0) &&
                                    <Alert color="danger">Please fill correct type!</Alert>
                        }
                        <div className="borde-gray-bottom job-type p-4">
                            <div className="divider-label mt-3">
                                <h6><span className="pr-2" disabled={props.newJob.errors}>Customer Details</span></h6>
                                <div className="row mt-3">
                                    <div className="col-12 col-sm-5 col-lg-4 mb-sm-2 image-select customer-field custom-form-wrap custom-detail-form">
                                        <Controller
                                            name={"disciplines"}
                                            control={control}
                                            innerRef={register("customer", { required: true })}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={(option) => setValue("customer", option, {shouldValidate: true})}
                                                        options={props.customers.data}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        styles={customStyles}
                                                        name="customer"
                                                        className="select-box-bottom-line"
                                                        value={customer}
                                                        placeholder=""
                                                        autoComplete="vin"
                                                        onFocus={() => setCustomerFocus(true)}
                                                        onBlur={() => setCustomerFocus(false)}
                                                        getOptionLabel={e => (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={e.icon_url} alt="" className="mr-2" />
                                                            <span style={{ marginLeft: 5 }}>{(e.firstname && e.lastname) ? e.firstname + " " + e.lastname : ''}</span>
                                                            </div>
                                                        )}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.customer && <small className="text-danger error-text">This field is required</small>}
                                        <label className={(getValues('customer') || customerFocus) ? "custom-form-label field-active" : "custom-form-label"} htmlFor="customer">Name</label>
                                    </div>

                                    <span className="col-md-1 or-divider">OR</span>
                                    <div className="col-12 col-sm-5 col-lg-4">
                                        <OutlinedButton
                                            className='m-0'
                                            svgFile={plus}
                                            title="Add New Customer"
                                            clickEvent={(e) => onNewCustomer()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="job-type px-4 pt-4">
                            <div className="row">
                                <FormGroup className="ro_number col-12 col-sm-5 col-lg-4 image-select customer-field custom-form-wrap mb-0 custom-detail-form">
                                    <Input
                                        type="text"
                                        name="ro_number"
                                        id="ro_number"
                                        className="select-box-bottom-line"
                                        placeholder=""
                                        autoComplete="ro_number"
                                        innerRef={ ro_numberRef }
                                        { ...ro_numberRest }
                                    />
                                    {errors.ro_number && <small className="text-danger error-text">This field is required</small>}
                                    <label className={getValues('ro_number') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="ro_number">RO Number</label>
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-12 col-sm-5 col-lg-4 image-select customer-field custom-form-wrap mb-0 custom-detail-form">
                                    <Controller
                                        name={"disciplines"}
                                        control={control}
                                        innerRef={register("assignableUser", { required: false })}
                                        render={() => {
                                            return (
                                                <Select
                                                    onChange={(option) => setValue("assignableUser", option, {shouldValidate: false})}
                                                    options={props.assignableUsers.data}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    styles={customStyles}
                                                    name="assignedUser"
                                                    className="select-box-bottom-line"
                                                    value={assignableUser}
                                                    placeholder=""
                                                    autoComplete="vin"
                                                    onFocus={() => setassignableUserFocus(true)}
                                                    onBlur={() => setassignableUserFocus(false)}
                                                    getOptionLabel={e => (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={e.icon_url} alt="" className="mr-2" />
                                                        <span style={{ marginLeft: 5 }}>{(e.firstname && e.lastname) ? e.firstname + " " + e.lastname : ''}</span>
                                                        </div>
                                                    )}
                                                />
                                            )
                                        }}
                                    />
                                    {errors.assignableUser && <small className="text-danger error-text">This field is required</small>}
                                    <label className={(getValues('assignableUser') || assignableUserFocus) ? "custom-form-label field-active" : "custom-form-label"} htmlFor="assignableUser">Assigned To</label>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="divider-label">
                                <h6><span className="pr-2">Car Details</span></h6>

                              <div className="car-detail-row mt-3 d-flex flex-column flex-md-row flex-md-wrap">
                                <div className="custom-form-wrap">
                                  <Controller
                                    name={"year"}
                                    control={control}
                                    innerRef={register("year", { required: true })}
                                    render={({}) => {
                                      return (
                                        <FormGroup className="imageDateInput d-flex flex-row align-items-center">
                                          <DatePicker
                                            selected={getValues("year")}
                                            onChange={(date) => setValue("year", date, { shouldValidate: true })}
                                            disabledKeyboardNavigation
                                            placeholderText=""
                                            showYearPicker
                                            name="year"
                                            id="year"
                                            autoComplete="year"
                                            minDate={new Date(1906, 12, 31)}
                                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
                                            dateFormat="yyyy"
                                            yearItemNumber={8}

                                          />
                                          <img src={require('../../assets/images/calendar.svg').default} alt="" />
                                        </FormGroup>
                                      )
                                    }}
                                  />
                                  {errors.year && <small className="text-danger error-text">This field is required</small>}
                                  <label className={getValues('year') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="year">Year</label>
                                </div>
                                <FormGroup className="image-select custom-form-wrap">
                                  <Controller
                                    name={"make"}
                                    control={control}
                                    innerRef={register("make_id", { required: true })}
                                    render={() => {
                                      return (
                                        <Select
                                          onChange={(option) => setValue("make_id", option, {shouldValidate: true})}
                                          placeholder=""
                                          options={props.vehicleMakes.data}
                                          isSearchable={true}
                                          isClearable={true}
                                          name="make"
                                          inputId="make"
                                          aria-dropeffect="popup"
                                          autoComplete="make"
                                          styles={customStyles}
                                          value={make}
                                          onFocus={() => setMakeFocus(true)}
                                          onBlur={() => setMakeFocus(false)}
                                          noOptionsMessage={({inputValue}) => "No make found"}
                                          getOptionLabel={e => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <img src={e.icon_url} alt="" className="mr-2" />
                                              <span style={{ marginLeft: 5 }}>{e.name}</span>
                                            </div>
                                          )}
                                        />
                                      )
                                    }}
                                  />
                                  {errors.make_id && <small className="text-danger error-text">This field is required</small>}
                                  <label className={(getValues('make_id') || makeFocus) ? "custom-form-label field-active" : "custom-form-label"} htmlFor="make" >Make</label>
                                </FormGroup>
                                <FormGroup className="mb-sm-2 custom-form-wrap">
                                  <Input
                                    type="text"
                                    name="model"
                                    id="model"
                                    className="select-box-bottom-line"
                                    placeholder=""
                                    autoComplete="model"
                                    innerRef={ modelRef }
                                    { ...modelRest }
                                  />
                                  {errors.model && <small className="text-danger error-text">This field is required</small>}
                                  <label className={getValues('model') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="model">Model</label>
                                </FormGroup>
                                <FormGroup className="mb-sm-2 custom-form-wrap">
                                  <Controller
                                    name={"vin"}
                                    control={control}
                                    innerRef={register("vin", { required: true })}
                                    render={({}) => {
                                      return (
                                        <Input
                                          type="text"
                                          name="vin"
                                          id="vin"
                                          className="select-box-bottom-line"
                                          autoComplete="vin"
                                          innerRef={ vinRef }
                                          { ...vinRest }
                                        />
                                      )
                                    }}
                                  />
                                  {errors.vin && <small className="text-danger error-text">This field is required</small>}
                                  <label className={getValues('vin') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="vin">VIN</label>
                                </FormGroup>
                              </div>
                                <div className="car-detail-row mt-4 d-flex flex-column flex-md-row flex-md-wrap">
                                  <FormGroup className="custom-form-wrap">
                                    <Input
                                      type="text"
                                      name="trim_code"
                                      id="trim_code"
                                      autoComplete="trim_code"
                                      className="select-box-bottom-line"
                                      placeholder=""
                                      innerRef={trimCodeRef}
                                      {...trimCodeRest}
                                    />
                                    {errors.trim_code && <small className="text-danger error-text">This field is required</small>}
                                    <label className={getValues('trim_code') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="trim_code">Trim Code</label>
                                  </FormGroup>
                                  <FormGroup className="gray-bg custom-form-wrap">
                                    <Input
                                      type="text"
                                      name="vehicle_license_plate"
                                      id="vehicle_license_plate"
                                      autoComplete="vehicle_license_plate"
                                      className="select-box-bottom-line"
                                      placeholder=""
                                      innerRef={licensePlateRef}
                                      {...licensePlateRest}
                                    />
                                    {errors.license_plate_number && <small className="text-danger error-text">This field is required</small>}
                                    <label className={getValues('license_plate_number') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="vehicle_license_plate">License Plate #</label>
                                  </FormGroup>

                                    <FormGroup className="custom-form-wrap">
                                        <Input
                                            type="text"
                                            name="exterior_color"
                                            id="exterior_color"
                                            autoComplete="exterior_color"
                                            className="select-box-bottom-line"
                                            placeholder=""
                                            innerRef={exteriorColorRef}
                                            {...exteriorColorRest}
                                        />
                                        {errors.exterior_color && <small className="text-danger error-text">This field is required</small>}
                                        <label className={getValues('exterior_color') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="exterior_color">Exterior Color</label>
                                    </FormGroup>
                                    <FormGroup className="custom-form-wrap">
                                        <Input
                                            type="text"
                                            name="paint_code"
                                            id="paint_code"
                                            autoComplete="paint_code"
                                            className="select-box-bottom-line"
                                            placeholder=""
                                            innerRef={paintCodeRef}
                                            {...paintCodeRest}
                                        />
                                        {errors.paint_code && <small className="text-danger error-text">This field is required</small>}
                                        <label className={getValues('paint_code') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="paint_code">Paint Code</label>
                                    </FormGroup>
                                </div>

                                <div className="car-detail-row mt-3 d-flex flex-column flex-md-row flex-md-wrap">
                                    <FormGroup className="col-12 col-lg-4 custom-form-wrap mb-0">
                                        <Input
                                            type="text"
                                            name="insuranceCompany"
                                            id="insuranceCompany"
                                            className="select-box-bottom-line"
                                            autoComplete="insuranceCompany"
                                            placeholder=""
                                            innerRef={insuranceCompanyRef}
                                            {...insuranceCompanyRest}
                                        />
                                        {errors.insurance_company && <small className="text-danger error-text">This field is required</small>}
                                        <label className={getValues('insurance_company') ? "custom-form-label field-active" : "custom-form-label"} htmlFor="insuranceCompany">Insurance Company</label>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <Modal key={`add_${modal}`} title="Add New Customer" closable={false} open={modal} footer={null} width={750} >
                <NewCustomer isModalOpen={modal} closeModal={() => setModal(false)} customerData={null} isEdit={false} />
            </Modal>
        </Spin>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        vehicleMakes: state.JobReducer.vehicleMakes,
        newJob: state.JobReducer.newJob,
        customer: state.JobReducer.customer && Object.keys(state.JobReducer.customer.data).length > 0
            ? state.JobReducer.customer.data
            : Object.keys(state.JobReducer.newJob.data).length > 0
                ? state.JobReducer.newJob.data.customer
                : null,
        customers: state.JobReducer.customers,
        assignableUsers: state.JobReducer.assignableUsers
    }
}

const mapDispatchToProps = (dispatch) => ({
    debouncedGetCustomers: () => debouncedGetCustomers(dispatch),
    debouncedGetVehicleMakes: () => debouncedGetVehicleMakes(dispatch),
    debouncedCreateNewJob: (data, flag) => debouncedCreateNewJob(dispatch, data, flag),
    debouncedGetChooseJob: (id, loading) => debouncedGetChooseJob(dispatch, id, loading),
    debouncedGetAllAssignableUsers: () => debouncedGetAllAssignableUsers(dispatch),
    initial: () => dispatch(initial()),
    setOpenModal: (payload) => dispatch(setModal(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewJob)