import React from 'react'

function JobCardSkeleton() {
    return (
        <div className='empty-card'>
            <div className='rectangleDiv' />
            <div className='w-100 d-flex flex-column justify-content-between'>
                <div className='first-col'>
                    <div className='skeleton skl-1' />
                    <div className='skeleton skl-2' />
                </div>
                <div className='second-col'>
                    <div className='skeleton skl-2' />
                    <div className='skeleton skl-3' />
                    <div className='skeleton skl-2' />
                </div>
                <div className='third-col'>
                    <div className='d-flex justify-content-between'>
                        <div className='skeleton skl-sort' />
                        <div className='skeleton skl-4' />
                    </div>
                    <div className='skeleton skl-button' />
                </div>
            </div>
        </div>
    )
}

export default JobCardSkeleton