import {
    INITIAL_STATES,
    GET_PROFILE,
    GET_USER_TYPE,
    UPDATE_USER_IN_ORG,
    ADD_USER_IN_ORG,
    UPDATE_PROFILE
} from "./actionTypes";
import { openNotification } from "../../helpers/globals";

const initialState = {
    user: {
        loading: false,
        errors: [],
        message: "",
        data: {},
        isSuccess: null,
        isUpdating: false
    },
    user_types: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    isAuthenticated: localStorage.getItem('h-access_token') ? true : false,
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PROFILE:
        state = { ...state, user: {...state.user, loading: true} }
        break
    case GET_PROFILE + `_SUCCESS`:
        state = { ...state, user: {...state.user, data: action.payload, loading: false, erros: [] } }
        break
    case GET_USER_TYPE:
        state = { 
            ...state,
            user_types: {...state.user_types, loading: true}
        }
        break
        
    case GET_USER_TYPE + `_SUCCESS`:
        state = { ...state, user_types: {...state.user_types, data: action.payload, loading: false} }
        break

    case GET_USER_TYPE + `_FAILED`:
        state = { ...state, user_types: {...state.user_types, data: [], errors: action.payload, loading: false} }
        break
    case UPDATE_USER_IN_ORG:
        break
        
    case UPDATE_USER_IN_ORG + `_SUCCESS`:
        break

    case UPDATE_USER_IN_ORG + `_FAILED`:
        break
    
    case UPDATE_PROFILE:
        state = { ...state, user: {...state.user, isUpdating: true, erros: []} }
        break
    
    case UPDATE_PROFILE + `_SUCCESS`:
        state = { ...state, user: {...state.user, data: action.payload, isUpdating: false, erros: [], isSuccess: true} }
        break
    case UPDATE_PROFILE + `_FAILED`:
        state = { ...state, user: {...state.user, isUpdating: false, erros: action.payload?.data?.message, isSuccess: false} }
        break
    case ADD_USER_IN_ORG + `_SUCCESS`:
        openNotification('Alert!', 'User added successfully!')
        if (Object.keys(action.payload).length > 0) {
            let temp = {
                ...state.user.data,
                organizations: [
                    {
                        ...state.user.data.organizations[0],
                        users: [
                            ...state.user.data.organizations[0].users,
                            action.payload
                        ]
                    }
                ]
            };
            state = {
                ...state,
                user: {
                    ...state.user,
                    data: temp,
                    loading: false
                }
            }
        }
        break

    case ADD_USER_IN_ORG + `_FAILED`:
        break
    case INITIAL_STATES:
        state = {
            ...initialState,
        }
        break;
    default:
        state = { ...state };
        break;
  }
  return state;
};

export default UserReducer;