import {
    CREATE_NEW_NOTE,
    UPDATE_NOTE,
    INITIAL_STATES,
    GET_NOTES,
    GET_NOTE_LISTS,
    GET_CHOOSE_NOTE,
    GET_NOTE_STATUSES,
    UPDATE_NOTE_STATUS,
    GET_COUNTER_DATA
} from "./actionTypes";
import debounce from 'lodash.debounce';

export const createNewNote = (payload, flag) => {
    return {
      type: flag ? UPDATE_NOTE : CREATE_NEW_NOTE,
      payload: payload
    }
}

export const createNewNoteSuccess = (payload, flag) => {
    return {
        type: flag ? UPDATE_NOTE + `_SUCCESS` : CREATE_NEW_NOTE + `_SUCCESS`,
        payload: payload
    }
}

export const createNewNoteFailed = (payload, flag) => {
    return {
        type: flag ? UPDATE_NOTE + `_FAILED` : CREATE_NEW_NOTE + `_FAILED`,
        payload: payload
    }
}

export const getNotes = (offset) => {
    return {
        type: GET_NOTES,
        offset: offset
    }
}

export const getNotesSuccess = (payload) => {
    return {
        type: GET_NOTES + `_SUCCESS`,
        payload: payload
    }
}

export const getNotesFailed = (payload) => {
    return {
        type: GET_NOTES + `_FAILED`,
        payload: payload
    }
}

export const getChooseNote = (id) => {
    return {
        type: GET_CHOOSE_NOTE,
        id: id
    }
}

export const getChooseNoteSuccess = (payload) => {
    return {
        type: GET_CHOOSE_NOTE + `_SUCCESS`,
        payload: payload
    }
}

export const getChooseNoteFailed = (payload) => {
    return {
        type: GET_CHOOSE_NOTE + `_FAILED`,
        payload: payload
    }
}

export const getNoteStatues = () => {
    return {
        type: GET_NOTE_STATUSES
    }
}


export const getNoteStatuesSuccess = (payload) => {
    return {
      type: GET_NOTE_STATUSES + `_SUCCESS`,
      payload: payload
    }
}

export const getNoteStatuesFailed = (payload) => {
    return {
      type: GET_NOTE_STATUSES + `_FAILED`,
      payload: payload
    }
}

export const getNoteList = (jobId) => {
    return {
        type: GET_NOTE_LISTS,
        jobId: jobId
    }
}

export const getNoteListSuccess = (payload) => {
    return {
      type: GET_NOTE_LISTS + `_SUCCESS`,
      payload: payload
    }
}

export const getNoteListFailed = (payload) => {
    return {
      type: GET_NOTE_LISTS + `_FAILED`,
      payload: payload
    }
}

export const updateNoteStatus = (jobId, statusId, itemOrders) => {
    return {
        type: UPDATE_NOTE_STATUS,
        payload: {
            jobId: jobId,
            statusId: statusId,
            itemOrders: itemOrders
        }
    }
}

export const updateNoteStatusSuccess = () => {
    return {
      type: UPDATE_NOTE_STATUS + `_SUCCESS`
    }
}

export const updateNoteStatusFailed = (payload) => {
    return {
      type: UPDATE_NOTE_STATUS + `_FAILED`
    }
}

export const updateCounterData = (payload) => {
    return {
        type: `UPDATE_COUNTER_DATA`,
        payload: payload
    }
}

export const debouncedUpdateCounterData = debounce((dispatch, payload) => {
    dispatch(updateCounterData(payload));
}, 300);

export const getCounterSuccess = (payload) => {
    return {
        type: GET_COUNTER_DATA + `_SUCCESS`,
        payload: payload
    }
}


export const initial = () => {
    return {
        type: INITIAL_STATES,
    }
}