import React from 'react'
import { connect } from 'react-redux'
import { Modal, Checkbox } from 'antd'
import { hideEmptyJobsModal, setIsNewJob } from '../../store/job/actions'

export const EmptyJobsModal = (props) => {
    const { emptyJobsModal, hideModal, setIsNewJob } = props
    const { isVisible } = emptyJobsModal

    const handleCancel = () => {
        hideModal()
    };

    const onChange = (e) => {
        localStorage.setItem("doNotShowEmptyJobsModal", e.target.checked)
    };

    const openNewJOb = (e) => {
        e.preventDefault()
        hideModal()
        setIsNewJob(true)
    }

    return (
        <Modal title={""} centered closable={false} open={isVisible} footer={null} width={750} onCancel={handleCancel}>
            <div className='empty-jobs-modal'>
                <div className='content-container'>
                    <div className='details'>
                        <div className='d-flex flex-column'>
                            <h1 className='title'>Let’s Begin!</h1>
                            <span className='body-text'>Welcome to Helios, your tool for illuminating the path to smoother shop management!<br /><br/>Dive in, explore the features, and when you're ready, click "Start a New Job" to kick off your first project.</span>
                        </div>
                        <div className='new-job-card job-item' onClick={(e)=> openNewJOb(e)}>
                            <div className='icon-add'>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 5.54167V1.16955C5.83333 0.523626 6.35117 0 7 0C7.64433 0 8.16667 0.531721 8.16667 1.16955V5.54167H12.8304C13.4764 5.54167 14 6.05951 14 6.70833C14 7.35267 13.4683 7.875 12.8304 7.875H8.16667V12.8304C8.16667 13.4764 7.64882 14 7 14C6.35567 14 5.83333 13.4683 5.83333 12.8304V7.875H1.16955C0.523626 7.875 0 7.35716 0 6.70833C0 6.064 0.531721 5.54167 1.16955 5.54167H5.83333Z" />
                                </svg>
                            </div>
                            <span className='card-title'>Start a New Job</span>
                        </div>
                    </div>
                    <div className='footer'>
                        <Checkbox onChange={onChange}>Do not show this message again. </Checkbox>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    emptyJobsModal: state.JobReducer.emptyJobsModal
})

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => dispatch(hideEmptyJobsModal()),
        setIsNewJob: (isNew) => dispatch(setIsNewJob(isNew))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyJobsModal)