import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ScrollContainer from 'react-indiana-drag-scroll';
import { dateFormat } from '../../helpers/stringFormat.js';
import { getDaysOfWeek, durations, weekTitles } from '../../helpers/getWeekDays';
import { MAX_WEEKS, useDimensions } from '../../helpers/globals';
import { DRAG_GENERAL_BACKGROUND, DRAG_DROP_BACKGROUND } from '../../helpers/globals';
import { FilledBrightButton } from '@oneblinktech-org/helios-base'
import { plus } from '../../fakeData/svgFiles.js'
import { Skeleton } from 'antd';
import ExpandedCard from '../../components/modals/ExpandedCard';
import Pusher from "pusher-js";
import {
    debouncedGetPlanningJobs,
    debouncedScheduleJobs,
    debouncedGetColorList,
    debouncedGetVehicleMakes,
    debouncedGetChooseJob,
    resetOpenJob,
    setIsNewJob
} from '../../store/job/actions'
import {
    debouncedUpdateCounterData,
    getNoteList
} from '../../store/note/actions'
import { useDocumentTitle } from '../../components/useDocumentTitle';
import Card from '../../components/dragAndDrop/card';

const LAST_WEEK = -1;
const SUNDAY = 0;
const SATURDAY = 6;

const onDragEnd = (props, result, columns, setColumns, setIsPusherEvent) => {

    const { source, destination, draggableId } = result;
    if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
    }

    let chooseDay = columns[destination.droppableId]['date']
  
    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.jobs];
        const destItems = [...destColumn.jobs];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
      
        let payload = {
            chooseDay: chooseDay,
            jobs: destItems.map(a => a.id)
        }

        props.debouncedScheduleJobs(payload)
        
        // setIsPusherEvent(true)
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                jobs: sourceItems
            },
            [destination.droppableId]: {
                ...destColumn,
                jobs: destItems
            }
        });
    } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.jobs];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        setColumns({
            ...columns,
            [source.droppableId]: {
                ...column,
                jobs: copiedItems
            }
        });
    }
};

const PlanningBoard = (props) => {

    useDocumentTitle("Helios - Planning Board")

    const [columns, setColumns] = useState({})
    const [skeleton, SetSkeleton] = useState(true)
    const [selectedJob, setSelectedJob] = useState({});
    const elementRefs = useRef([])
    const initialRender = useRef(true);
    const [isCardChanged, setIsCardChanged] = useState(false);
    const [isPusherEvent, setIsPusherEvent] = useState(false);
    const [columnHeights, setColumnHeights] = useState([200, 200, 200, 200, 200])
    const [archivedJob, setArchivedJob] = useState(false);
    const [pulseAnimation, setPulseAnimation] = useState(false)

    useEffect(() => {
        var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        });

        var channelProduct = pusher.subscribe("product-board");
        channelProduct.bind("job-event", (data) => {
            if (data.event === 'card changed') {
                getPlanningSchedules(false)
                if (data?.value && selectedJob) {
                    props.getNoteList(data.value)
                    setIsCardChanged(true)
                }
            }
        })

        var channel = pusher.subscribe("planning-board");
        channel.bind("move-event", (data, metadata) => {
            if(data.event == 'planning scheduled' && !isPusherEvent) {
                getPlanningSchedules(false)
            }

            setIsPusherEvent(false)
        });

        var orgChannel = pusher.subscribe("organizations");
        orgChannel.bind("choose-event", (data) => {
            getPlanningSchedules(false)
            pusher.unsubscribe(orgChannel);
        });

        return (() => {
            pusher.unsubscribe('planning-board')
            pusher.unsubscribe('organizations')
            pusher.unsubscribe('product-board')
        })
    }, [isPusherEvent])

    useEffect(() => {
        if (props?.colorList?.data?.length === 0)
            props.debouncedGetColorList()

        let isMounted = true
        if(isMounted) {
            if (Object.keys(props.jobs).length > 0) {
                setColumns(props.jobs)
            }
        }
        return () => {
            isMounted = false
        }

    }, [props.jobs])

    useEffect(() => {
        getPlanningSchedules(true)
        if(!props?.vehicleMakes?.data || Object.values(props?.vehicleMakes?.data)?.length===0)
            props.debouncedGetVehicleMakes()
    }, [])

    useEffect(() => {
        props.debouncedUpdateCounterData((columns[MAX_WEEKS]?.jobs)?.length)
    }, [columns[MAX_WEEKS]])

    useEffect(() => {
        if (props.loading) {
            SetSkeleton(true)
        } else {
            SetSkeleton(false)
        }
    }, [props.loading])

    const getPlanningSchedules = (isLoading) => {
        let days = getDaysOfWeek(LAST_WEEK, MAX_WEEKS)
        let dayDuration = {
            startDay: days[0],
            endDay: days[MAX_WEEKS - 1]
        }
        
        props.debouncedGetPlanningJobs(dayDuration, isLoading)
    }

    const onToggleCard = (open, columnId, itemId) => {
        let oldJobs = (columns[columnId])['jobs']
        let updatedjobs = oldJobs.map(obj => {
            if (obj.id == itemId) {
                return {...obj, open: open ? 0 : 1}
            }
            return obj
        })

        setColumns({
            ...columns,
            [columnId]: {
                ...columns[columnId],
                jobs: updatedjobs
            }
        })
    }

    const changeBackground = (color) => {
        setSelectedJob({...selectedJob, color_id: color?.id, job_color: color})
    }

    useEffect(() => {
        const init = () => {
            setTimeout(() => {
                const li = document.querySelectorAll('.calendar_box:nth-child(7n+1)')
                let heights = []
                li.forEach(itm => {
                    var style = window.getComputedStyle(itm)
                    var exactHeight = parseFloat(style.height);
                    heights.push( Number(parseFloat(exactHeight).toFixed(2)))
                })
                setColumnHeights(heights)
            }, 200)
        }
        init()
    }, [columns])

    const onDragUpdate = (e) => {
        setTimeout(() => {
            const li = document.querySelectorAll('.calendar_box:nth-child(7n+1)')
            let heights = []
            li.forEach(itm => {
                var style = window.getComputedStyle(itm)
                var exactHeight = parseFloat(style.height);
                heights.push( Number(parseFloat(exactHeight).toFixed(2)))
            })
            setColumnHeights(heights)
        }, 200)
    }

    const onArchiveJob = (status) => {
        // status - 0: archive, 1: unarchive
        if(status == 0) {
            setArchivedJob(true)
        }else if(status == 1) {
            setPulseAnimation(false)
            setArchivedJob(false)
            setTimeout(() => {
                setPulseAnimation(false)
            }, 5000)
        }
    }

    return (
        <div className="planning-board">
            <div className="header pb-3">
                <h5 className="title">Planning Board</h5>
                <div className="action">
                    <div id={`job_new`} className='job-item' >
                        <FilledBrightButton
                            svgFile={plus}
                            title="New Job"
                            clickEvent={(e) => props.setIsNewJob(true)}
                        />
                    </div>
                </div>
            </div>
            <DragDropContext
                onDragUpdate={(e)=> onDragUpdate(e) }
                onDragEnd={result => onDragEnd(props, result, columns, setColumns, setIsPusherEvent)}
            >
                <div className="d-flex flex-row board-wrap">
                    <div className="d-flex flex-column justify-content-start pending">
                        <div className="d-flex flex-row justify-content-between align-items-center p-1 px-2 header">
                            <h6 className="mb-0">Unscheduled Jobs</h6>
                            <div>{Object.keys(columns).length > 0 ? columns[MAX_WEEKS].jobs.length : 0}</div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                height: "100%",
                                alignItems: "start"
                            }}
                        >
                            {/* columns of 56 are unscheduled jobs */}
                            <div style={{ margin: 8, width: '100%', height: '100%' }}>
                                {
                                    skeleton ?
                                    <Skeleton active />
                                    :
                                    Object.keys(columns).length > 0 &&
                                    <Droppable droppableId={String(MAX_WEEKS)} key={String(MAX_WEEKS)}>
                                    {(provided, snapshot) => {
                                        return (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={snapshot.isDraggingOver? 'active-color':'pannel'}
                                            style={{
                                                padding: 4,
                                                minHeight: 500,
                                                height: "100%"
                                            }}
                                        >
                                            {columns[MAX_WEEKS].jobs.map((item, index) => {
                                            return (
                                                <Draggable
                                                key={item.id}
                                                draggableId={(item.id).toString()}
                                                index={index}
                                                >
                                                {(provided, snapshot) => {
                                                    return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='drag_drop-card'
                                                        onClick={() => {
                                                            setSelectedJob(item)
                                                            props.setIsNewJob(false)
                                                            props.debouncedGetChooseJob(item.id, true)
                                                        }}
                                                    >
                                                        <Card data={item} colorList={props.colorList} />
                                                    </div>
                                                    );
                                                }}
                                                </Draggable>
                                            );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                        );
                                    }}
                                    </Droppable>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="calendar-container d-flex flex-row">
                        <div className="d-flex flex-column leftside">
                            {
                                durations.length > 0 &&
                                durations.map((duration, key) => 
                                    <div key={`${key}_${columnHeights?.length > 0 ? columnHeights[key] : 0}`} className="duration-item" style={{ minHeight: columnHeights?.length > 0 ? columnHeights[key] : 200 }} >
                                        <div className="wrap d-flex flex-column">
                                            <span>{duration.text}</span>
                                            <small className="d-flex flex-row align-items-center">
                                                {dateFormat(getDaysOfWeek(duration.key, 7)[SUNDAY], 'DUR_DATE')} 
                                                <img src={require('../../assets/images/right-arrow.svg').default} />
                                                {dateFormat(getDaysOfWeek(duration.key, 7)[SATURDAY], 'DUR_DATE')}
                                            </small>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <ScrollContainer
                            className='w-100'
                            ignoreElements=".planning-drag_drop-card, *[prevent-drag-scroll]"
                            hideScrollbars={false}
                            activationDistance={250}
                        >
                        <ol className="calendar">
                            {
                                weekTitles.length > 0 &&
                                weekTitles.map((title, key) => 
                                    <li key={key} className="calendar-day calendar-day--begin-week calendar-day--past calendar-day--not-month week-day px-3 py-2">{title}</li>
                                )
                            }
                                {
                                    Object.keys(columns).length > 0 &&
                                    Object?.entries(columns).filter(([ci, c], i) => ci != MAX_WEEKS && ci != (MAX_WEEKS + 1)).map(([columnId, column], index) =>
                                        <li ref={(element) => elementRefs.current[index] = element} key={index} className={ 'ref_' + index + " calendar_box calendar-day calendar-day--begin-week calendar-day--past calendar-day--not-month"}>
                                            <Droppable droppableId={columnId.toString()} key={columnId.toString()}>
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={{
                                                                padding: 4,
                                                                minHeight: 200,
                                                                height: "100%",
                                                                width: "100%"
                                                            }}
                                                            className={`${snapshot.isDraggingOver? 'active-color':'pannel'} p-2 d-flex flex-column justify-content-start`}
                                                        >
                                                            {
                                                            skeleton ?
                                                            <Skeleton active />
                                                            :
                                                            column.jobs.map((item, index) =>
                                                                <Draggable
                                                                    key={item.id.toString()}
                                                                    draggableId={item.id.toString()}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => {
                                                                        return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className='drag_drop-card'
                                                                            onClick={() => {
                                                                                setSelectedJob(item)
                                                                                props.setIsNewJob(false)
                                                                                props.debouncedGetChooseJob(item.id, true)
                                                                            }}
                                                                            >
                                                                            <Card data={item} colorList={props.colorList}/>
                                                                        </div>
                                                                        );
                                                                    }}
                                                                </Draggable>
                                                            )}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                }
                                            </Droppable>
                                        </li>
                                    )
                                }
                        </ol>
                        </ScrollContainer>
                    </div>
                    <div className="d-flex flex-column justify-content-start pending checkin">
                        <div className="d-flex flex-row justify-content-between align-items-center p-1 header">
                            <h6 className="mb-0">Check-in</h6>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                height: "100%",
                                alignItems: "start"
                            }}
                        >
                            {/* columns of 57 are checkin jobs */}
                            <div style={{ margin: 6, width: '100%', height: '100%' }}>
                                {
                                    skeleton ?
                                    <Skeleton active />
                                    :
                                    Object.keys(columns).length > 0 &&
                                    <Droppable droppableId={(MAX_WEEKS + 1)?.toString()} key={(MAX_WEEKS + 1)?.toString()}>
                                    {(provided, snapshot) => {
                                        return (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={snapshot.isDraggingOver? 'active-color':'pannel'}
                                            style={{
                                                padding: 2,
                                                minHeight: 500,
                                                height: "100%"
                                            }}
                                        >
                                            {columns[(MAX_WEEKS+1)].jobs.map((item, index) => {
                                            return (
                                                <Draggable
                                                key={item.id}
                                                draggableId={(item.id).toString()}
                                                index={index}
                                                >
                                                {(provided, snapshot) => {
                                                    return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='drag_drop-card'
                                                        onClick={() => {
                                                            setSelectedJob(item)
                                                            props.setIsNewJob(false)
                                                            props.debouncedGetChooseJob(item.id, true)
                                                        }}
                                                    >
                                                        <Card data={item} colorList={props.colorList} />
                                                    </div>
                                                    );
                                                }}
                                                </Draggable>
                                            );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                        );
                                    }}
                                    </Droppable>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        jobs: state.JobReducer.planningJobs.data,
        colorList: state.JobReducer.colorList,
        loading: state.JobReducer.planningJobs.loading,
        vehicleMakes: state.JobReducer.vehicleMakes,
        newJob: state.JobReducer.newJob,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        debouncedGetPlanningJobs: (dayDuration, isLoading) => debouncedGetPlanningJobs(dispatch, dayDuration, isLoading),
        debouncedScheduleJobs: (data) => debouncedScheduleJobs(dispatch, data),
        debouncedGetColorList: () => debouncedGetColorList(dispatch),
        debouncedUpdateCounterData: (count) => debouncedUpdateCounterData(dispatch, count),
        debouncedGetVehicleMakes: () => debouncedGetVehicleMakes(dispatch),
        debouncedGetChooseJob: (id, loading) => debouncedGetChooseJob(dispatch, id, loading),
        resetOpenJob: () => dispatch(resetOpenJob()),
        getNoteList: (jobId) => dispatch(getNoteList(jobId)),
        setIsNewJob: (isNew) =>  dispatch(setIsNewJob(isNew))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningBoard)